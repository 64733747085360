body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.categoryVideoContainer{
    background-color: #113;
    color: #ddd;
    padding: 20px;
}
.dropzone{
    border: 2px dashed rgba(255,255,255,0.2);
    border-radius: 10px;
    padding: 50px 0;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255,255,255,0.6);
  }
  .dropzone.active{
    border-color:#fff;
  }
  .file{
    text-decoration:none;
    color: rgba(255,255,255,0.8);
    display:block;
    background-color: #41415d;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    position:relative;
    overflow: hidden;
  }
  .file .progress {
    background-color: rgba(80,80,120, .8);
    position: absolute;
    inset:0;
    width:0;
    padding-top: 2px;
    text-align: center;
    margin-top: 35px
  }
  .file .progress.done{
    top: 100px;
  }
.table-wrapper {
    overflow-y: scroll;
    max-height: 300px;
};
.mapApp {
    font-family: sans-serif;
    text-align: center;
    height: 70vh;
}
  
.shapes-map {
    height: 100%;
    width: 100%;
    /* position: absolute !important */
}
  
