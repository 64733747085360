.mapApp {
    font-family: sans-serif;
    text-align: center;
    height: 70vh;
}
  
.shapes-map {
    height: 100%;
    width: 100%;
    /* position: absolute !important */
}
  