.categoryVideoContainer{
    background-color: #113;
    color: #ddd;
    padding: 20px;
}
.dropzone{
    border: 2px dashed rgba(255,255,255,0.2);
    border-radius: 10px;
    padding: 50px 0;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255,255,255,0.6);
  }
  .dropzone.active{
    border-color:#fff;
  }
  .file{
    text-decoration:none;
    color: rgba(255,255,255,0.8);
    display:block;
    background-color: #41415d;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    position:relative;
    overflow: hidden;
  }
  .file .progress {
    background-color: rgba(80,80,120, .8);
    position: absolute;
    inset:0;
    width:0;
    padding-top: 2px;
    text-align: center;
    margin-top: 35px
  }
  .file .progress.done{
    top: 100px;
  }